function show_message_upgrade_pp(message, type) {

	type = typeof type !== 'undefined' ? type : 'error';
	var button = {label: "Jetzt upgraden", onclick: "window.location = '/promo-pp.html';"};

	modal.show_message( message , 1000000, type, 'Abbrechen', button);
}

function show_message_upgrade_fl(message, type) {

	type = typeof type !== 'undefined' ? type : 'error';
	var button = {label: "Jetzt upgraden", onclick: "window.location = '/account/create.php';"};

	modal.show_message( message , 1000000, type, 'Abbrechen', button);
}